import {Component, Input, OnInit} from '@angular/core';
import {Movies} from '../../models/movies';

@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss']
})
export class SliderComponent implements OnInit {
   @Input() sliderConfig;
   @Input() movies: any;
   @Input() title: string;

  constructor() { }

  ngOnInit(): void {
    console.log('=========================')
    console.log(this.movies);
    console.log('=========================')
  }

}
