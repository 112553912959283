<h2>{{title}}</h2>

<div class="movie">
  <div class="movie__images">
    <div class="thumbnails">
      <ngx-slick-carousel [config]="sliderConfig" class="carousel">
        <div class="slick-item" *ngFor="let m of movies" ngxSlickItem>
          <img src="https://image.tmdb.org/t/p/w200{{m?.poster_path}}" alt="{{m.title}}">
        </div>
      </ngx-slick-carousel>
    </div>
  </div>
</div>
