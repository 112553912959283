<link href="https://fonts.googleapis.com/css?family=Roboto:300,400,500&display=swap" rel="stylesheet">
<link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet">

<div class="container">
  <div class="header">
    <!--BANNER AREA-->
    <div class="header__banner" [ngStyle]="{
      'background-image' : 'linear-gradient(rgba(0,0,0,0.8), rgba(0,0,0,0.3)), url('+headerBGUrl+')'
      }">
      <h2>{{trending?.results[0].title}}</h2>
 


    </div> <!--BANNER ENDS HERE-->


    <!--MAIN AREA-->
    <div class="header__main" id="stickyHeader" [class.sticky]="sticky" #stickHeader>
      <div class="header__left">
        <a routerLink="/">
          <img src="assets/netflix.svg" alt="logo">
        </a>
      </div>


    </div> <!--HEADER MAIN ENDS HERE-->

  </div>

  <div class="body" style="margin-top: 30rem;">

    <div class="categorias">
      <h2>Tendencias</h2>
      <div class="movie">
        <div class="movie__images">
          <div class="thumbnails">
            <ngx-slick-carousel [config]="sliderConfig" class="carousel" style="cursor: pointer;">
              <div class="slick-item" *ngFor="let m of trending.results" ngxSlickItem>
                <img src="https://image.tmdb.org/t/p/w200{{m?.poster_path}}" alt="{{m.title}}">
              </div>
            </ngx-slick-carousel>
          </div>
        </div>
      </div>
    </div>

    <div class="categorias">
      <h2>Los más valorados</h2>
      <div class="movie">
        <div class="movie__images">
          <div class="thumbnails">
            <ngx-slick-carousel [config]="sliderConfig" class="carousel" style="cursor: pointer;">
              <div class="slick-item" *ngFor="let m of topRated.results" ngxSlickItem>
                <img src="https://image.tmdb.org/t/p/w200{{m?.poster_path}}" alt="{{m.title}}">
              </div>
            </ngx-slick-carousel>
          </div>
        </div>
      </div>
    </div>


    <div class="categorias">
      <h2>Jugando ahora</h2>
      <div class="movie">
        <div class="movie__images">
          <div class="thumbnails">
            <ngx-slick-carousel [config]="sliderConfig" class="carousel" style="cursor: pointer;">
              <div class="slick-item" *ngFor="let m of nowPlaying.results" ngxSlickItem>
                <img src="https://image.tmdb.org/t/p/w200{{m?.poster_path}}" alt="{{m.title}}">
              </div>
            </ngx-slick-carousel>
          </div>
        </div>
      </div>
    </div>

    <div class="categorias">
      <h2>Originales</h2>
      <div class="movie">
        <div class="movie__images">
          <div class="thumbnails">
            <ngx-slick-carousel [config]="sliderConfig" class="carousel" style="cursor: pointer;">
              <div class="slick-item" *ngFor="let m of originals.results" ngxSlickItem>
                <img src="https://image.tmdb.org/t/p/w200{{m?.poster_path}}" alt="{{m.title}}">
              </div>
            </ngx-slick-carousel>
          </div>
        </div>
      </div>
    </div>

    <div class="categorias">
      <h2>Popular</h2>
      <div class="movie">
        <div class="movie__images">
          <div class="thumbnails">
            <ngx-slick-carousel [config]="sliderConfig" class="carousel" >
              <div class="slick-item" *ngFor="let m of popular.results" ngxSlickItem>
                <img src="https://image.tmdb.org/t/p/w200{{m?.poster_path}}" alt="{{m.title}}">
              </div>
            </ngx-slick-carousel>
          </div>
        </div>
      </div>
    </div>

    <!--
      <app-slider title="Trending" [movies]="trending" [sliderConfig]="sliderConfig"></app-slider>
      <app-slider title="Top Rated" [movies]="topRated" [sliderConfig]="sliderConfig"></app-slider>
      <app-slider title="Now Playing" [movies]="nowPlaying" [sliderConfig]="sliderConfig"></app-slider>
      <app-slider title="Originals" [movies]="originals" [sliderConfig]="sliderConfig"></app-slider>
      <app-slider title="Popular" [movies]="popular" [sliderConfig]="sliderConfig"></app-slider>
    -->
  </div>



</div>
